import React, { useState, useEffect } from "react";
import functions from "../scripts/EstrellasVolantesFunctions";
import "../scripts/jQueryRotateCompressed.2.2";
import "../scripts/jquery.easing.1.3";

const EstrellasVolantes = () => {
  const [defaultYear, setDefaultYear] = useState("1974");
  const [firstClick, setFirstClick] = useState(true);

  const handleSetDefaultYear = (valor) => {
   /*  console.log("Valor:", parseInt(valor.target.value)); */
    if(parseInt(valor.target.value) <=0 || isNaN(parseInt(valor.target.value)) ){
      setDefaultYear("");
    }else{
      setDefaultYear(valor.target.value);
    }
    /* console.log(valor.target.value.length); */
    if(valor.target.value.length === 4){
      functions.HideTicket();
    }
  };

  const handleRadioChange = () => {
    functions.MoveCompass();
    functions.HideTicket();
    functions.CheckedElement();
  };

  useEffect(() => {
    if(firstClick){
      functions.MoveCompass();
      setFirstClick(false);
    }
    functions.ClickIt(); 
  },[setFirstClick]);

  return (
    <div className="container mt-4">
      <div id="content">
        <div id="col_center">
          <div id="col_center_in">
              <div className="elevado">
                <div className="in">
                  <span className="tl"></span>
                  <span className="t"></span>
                  <span className="tr"></span>
                  <span className="r"></span>
                  <span className="br"></span>
                  <span className="b"></span>
                  <span className="bl"></span>
                  <span className="l"></span>
                  <h2 className="text-center">
                    <span className="retro___display_">
                      Calculador on line de Estrellas Volantes
                    </span>
                  </h2>
                  <div className="row row-eq-height align-items-center">
                    <div className="col-sm">
                      <div id="estrellasvolantes_wrapper">
                        <form
                          id="estrellasvolantes"
                          name="estrellasvolantes"
                          method="post"
                          action=""
                        >
                          <div className="in">
                            <span className="stud  b_l"></span>
                            <span className="stud  t_l"></span>
                            <span className="stud  t_r"></span>
                            <span className="stud  b_r"></span>
                            <p>
                              1. Ingresa el año de construcción:&emsp;
                              <input
                                name="jaar"
                                type="number"
                                id="jaar"
                                value={defaultYear}
                                size="5"
                                onChange={handleSetDefaultYear}
                              />
                            </p>
                            <p>
                              (si tu casa se construyó entre el 1 de enero y el
                              4 de febrero, ingresa el año anterior)
                            </p>
                            <p>
                                2. Selecciona la dirección del frente en grados
                                <br />
                                (ej: si la dirección es 90º elige E2: 82.5-97.5):
                            </p>
                            <div className="tablero">
                              <table border="0" cellSpacing="0" cellPadding="0">
                                <tr>
                                  <td className="myradio">
                                    <div className="btn_backlit">
                                      <label className="label-tablero" id="label1">
                                        SE2
                                        <input
                                          type="radio"
                                          name="graden"
                                          value="11"
                                          id="orientacion_11"
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="btn_backlit">
                                      <label className="label-tablero" id="label2">
                                        SE3
                                        <input
                                          type="radio"
                                          name="graden"
                                          value="12"
                                          id="orientacion_12"
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="btn_backlit">
                                      <label className="label-tablero" id="label3">
                                        S1
                                        <input
                                          type="radio"
                                          name="graden"
                                          value="13"
                                          id="orientacion_13"
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="btn_backlit">
                                      <label className="label-tablero" id="label4">
                                        S2
                                        <input
                                          type="radio"
                                          name="graden"
                                          value="14"
                                          id="orientacion_14"
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="btn_backlit">
                                      <label className="label-tablero" id="label5">
                                        S3
                                        <input
                                          type="radio"
                                          name="graden"
                                          value="15"
                                          id="orientacion_15"
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="btn_backlit">
                                      <label className="label-tablero" id="label6">
                                        SW1
                                        <input
                                          type="radio"
                                          name="graden"
                                          value="16"
                                          id="orientacion_16"
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="btn_backlit">
                                      <label className="label-tablero" id="label7">
                                        SW2
                                        <input
                                          type="radio"
                                          name="graden"
                                          value="17"
                                          id="orientacion_17"
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="btn_backlit">
                                      <label className="label-tablero" id="label8">
                                        SE1
                                        <input
                                          type="radio"
                                          name="graden"
                                          value="10"
                                          id="orientacion_10"
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                  <td
                                    colSpan="5"
                                    rowSpan="5"
                                    align="center"
                                    valign="bottom"
                                    className="luopan"
                                  >
                                    <div className="luopan">
                                      <div className="luopan_in">
                                        <span className="aguja_shadow">
                                          <span className="aguja_in"></span>
                                        </span>
                                        <span className="aguja_color"></span>
                                        <span className="aguja">
                                          <span className="aguja_in">
                                            <span className="aguja_color"></span>
                                          </span>
                                        </span>
                                        <span className="aguja_center"></span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="btn_backlit">
                                      <label className="label-tablero" id="label9">
                                        SW3
                                        <input
                                          type="radio"
                                          name="graden"
                                          value="18"
                                          id="orientacion_18"
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="btn_backlit">
                                      <label className="label-tablero" id="label10">
                                        E3
                                        <input
                                          type="radio"
                                          name="graden"
                                          value="9"
                                          id="orientacion_9"
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="btn_backlit">
                                      <label className="label-tablero" id="label11">
                                        W1
                                        <input
                                          type="radio"
                                          name="graden"
                                          value="19"
                                          id="orientacion_19"
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="btn_backlit">
                                      <label className="label-tablero" id="label12">
                                        E2
                                        <input
                                          type="radio"
                                          name="graden"
                                          value="8"
                                          id="orientacion_8"
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="btn_backlit">
                                      <label className="label-tablero" id="label13">
                                        W2
                                        <input
                                          type="radio"
                                          name="graden"
                                          value="20"
                                          id="orientacion_20"
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="btn_backlit">
                                      <label className="label-tablero" id="label14">
                                        E1
                                        <input
                                          type="radio"
                                          name="graden"
                                          value="7"
                                          id="orientacion_7"
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="btn_backlit">
                                      <label className="label-tablero" id="label15">
                                        W3
                                        <input
                                          type="radio"
                                          name="graden"
                                          value="21"
                                          id="orientacion_21"
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="btn_backlit">
                                      <label className="label-tablero" id="label16">
                                        NE3
                                        <input
                                          type="radio"
                                          name="graden"
                                          value="6"
                                          id="orientacion_6"
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="btn_backlit">
                                      <label className="label-tablero" id="label17">
                                        NW1
                                        <input
                                          type="radio"
                                          name="graden"
                                          value="22"
                                          id="orientacion_22"
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="btn_backlit">
                                      <label className="label-tablero" id="label18">
                                        NE2
                                        <input
                                          type="radio"
                                          name="graden"
                                          value="5"
                                          id="orientacion_5"
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="btn_backlit">
                                      <label className="label-tablero" id="label19">
                                        NE1{" "}
                                        <input
                                          type="radio"
                                          name="graden"
                                          value="4"
                                          id="orientacion_4"
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="btn_backlit">
                                      <label className="label-tablero" id="label20">
                                        N3
                                        <input
                                          type="radio"
                                          name="graden"
                                          value="3"
                                          id="orientacion_3"
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="btn_backlit">
                                      <label className="label-tablero" id="label21">
                                        N2
                                        <input
                                          type="radio"
                                          name="graden"
                                          value="2"
                                          id="orientacion_2"
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="btn_backlit">
                                      <label className="label-tablero" id="label22">
                                        N1
                                        <input
                                          name="graden"
                                          type="radio"
                                          id="orientacion_1"
                                          value="1"
                                          checked="checked"
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="btn_backlit">
                                      <label className="label-tablero" id="label23">
                                        NW3
                                        <input
                                          type="radio"
                                          name="graden"
                                          value="24"
                                          id="orientacion_24"
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="btn_backlit">
                                      <label className="label-tablero" id="label24">
                                        NW2
                                        <input
                                          type="radio"
                                          name="graden"
                                          value="23"
                                          id="orientacion_23"
                                          onChange={handleRadioChange}
                                        />
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                              <div className="boton-resultados">
                                <a href="#resultados">
                                  <button type="button" className="btn btn-default btn-lg">
                                    <i className="fas fa-angle-down"></i> Resultados
                                  </button>
                                </a>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="col-sm">
                      <div className="paper resultadoticket" id="resultados">
                        <div className="in in-estrellasvolantes">
                          <span className="tl"></span>
                          <span className="t"></span>
                          <span className="tr"></span>
                          <span className="r"></span>
                          <span className="br"></span>
                          <span className="b"></span>
                          <span className="bl"></span>
                          <span className="l"></span>
                          <span className="stud  b_l"></span>
                          <span className="stud  t_l"></span>
                          <span className="stud  t_r"></span>
                          <span className="stud  b_r"></span>
                          <h3>Resultados</h3>
                          <table
                            cellSpacing="6"
                            cellPadding="0"
                            border="0"
                            id="estrellas_resultado"
                          >
                            <tbody>
                              <tr>
                                <td>
                                  <h5 id="sel"></h5>
                                  <h2 id="se"></h2>
                                  <h5 id="ser"></h5>
                                </td>
                                <td>
                                  <h5 id="sl"></h5>
                                  <h2 id="s"></h2>
                                  <h5 id="sr"></h5>
                                </td>
                                <td>
                                  <h5 id="swl"></h5>
                                  <h2 id="sw"></h2>
                                  <h5 id="swr"></h5>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h5 id="el"></h5>
                                  <h2 id="e"></h2>
                                  <h5 id="er"></h5>
                                </td>
                                <td>
                                  <h5 id="ml"></h5>
                                  <h2 id="m"></h2>
                                  <h5 id="mr"></h5>
                                </td>
                                <td>
                                  <h5 id="wl"></h5>
                                  <h2 id="w"></h2>
                                  <h5 id="wr"></h5>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h5 id="nel"></h5>
                                  <h2 id="ne"></h2>
                                  <h5 id="ner"></h5>
                                </td>
                                <td>
                                  <h5 id="nl"></h5>
                                  <h2 id="n"></h2>
                                  <h5 id="nr"></h5>
                                </td>
                                <td>
                                  <h5 id="nwl"></h5>
                                  <h2 id="nw"></h2>
                                  <h5 id="nwr"></h5>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="3" id="laptext">
                                  <div id="laptextdiv">
                                    {" "}
                                    <b>
                                      <span id="spanjaar"></span>
                                    </b>{" "}
                                    <b>
                                      <span id="spanteken"></span>
                                    </b>
                                    <b>
                                      <span id="cnumber"></span>
                                    </b>{" "}
                                    <b>
                                      <span id="cnumber1"></span>
                                    </b>{" "}
                                    <b>
                                      <span id="richtingface"></span>
                                    </b>{" "}
                                    <b>
                                      <span id="facing"></span>
                                    </b>{" "}
                                    <b>
                                      <span id="richtingsit"></span>
                                    </b>{" "}
                                    <b>
                                      <span id="sitting"></span>
                                    </b>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clearboth mb-4"></div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EstrellasVolantes;
