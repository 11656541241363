import React from 'react';
import iosShare from '../images/ios-share.png';
import androidShare from '../images/android-share.png';

const LinkTablas = ()=>{
    return(
        <div className="container">
            <div className="row text-center fila-tablas">
                {/* <div className="col-sm">
                    <h1 className="title-tablas mb-4">Tablas para Feng Shui y Astrología China</h1>
                    <p>Tabla de Ming Gua y Animal Celestial <a href='http://www.fengshui-argentina.com/index.php/download_file/view/91/169/' target="_blank" className="link-tablas">Click Aqui.</a></p>
                    <p>Tabla de la Flor del Melocotón <a href='http://www.fengshui-argentina.com/index.php/download_file/view/90/169/' target="_blank" className="link-tablas">Click Aqui.</a></p>
                    <p>Tabla de Dirección Sheng Chi <a href='http://www.fengshui-argentina.com/index.php/download_file/view/92/169/' target="_blank" className="link-tablas">Click Aqui.</a></p>
                </div> */}
                <div className='col-sm'>
                    <h1 className="title-tablas mb-4">Instrucciones para instalación de app en dispositivos móviles</h1>
                    <div className='row'>
                        <div className='col'>
                            <p><strong>iOS</strong></p>
                            <p>1. Ingresar a la app desde Safari</p>
                            <p>2. Click sobre el botón "Compartir" <img src={iosShare} width={18} /></p>
                            <p>3. Seleccionar 'Agregar a Inicio' y luego 'Agregar'</p>
                        </div>
                        <div className='col'>
                            <p><strong>Android</strong></p>
                            <p>1. Ingresar a la app desde Chrome</p>
                            <p>2. Click sobre el botón opciones <img src={androidShare} width={18} /></p>
                            <p>3. Seleccionar 'Agregar a la pantalla principal' y luego 'Agregar'</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LinkTablas;