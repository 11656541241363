import React, { useState } from "react";
import functions from "../scripts/CuatroPilaresFunctions";

const CuatroPilares = () => {
  const [defaultYear, setDefaultYear] = useState("1900");

  const handleClickSubmit = () => {
    const form = document.getElementById("calculo4pilares");
    functions.compute(form);
    /* console.log(form); */
  };

  const handleSetDefaultYear = (valor) => {
    if (
      parseInt(valor.target.value) <= 0 ||
      isNaN(parseInt(valor.target.value))
    ) {
      setDefaultYear("");
    } else {
      setDefaultYear(valor.target.value);
    }
  };
  return (
    <div>
      <div className="container mt-4">
        <div id="content">
          <div id="content_in">
            <div id="col_center">
              <div className="elevado">
                <div className="in">
                  <div id="calculo4pilares_wrapper">
                    <h2 className="text-center">
                      Calculador de los Cuatro Pilares
                    </h2>
                    <h4 className="text-center">
                      Cálculo de los Cuatro Pilares y el Ming Gua
                    </h4>
                    <div className="row row-eq-height align-items-center">
                      <div className="col-sm">
                        <div className="col1">
                          <div className="in in-cuatropilares">
                            <div className="elevado">
                              <div className="in">
                                <span className="stud  b_l"></span>
                                <span className="stud  t_l"></span>
                                <span className="stud  t_r"></span>
                                <span className="stud  b_r"></span>
                                <p>Por favor ingresa tus datos debajo:</p>
                                <form id="calculo4pilares">
                                  <table
                                    border="0"
                                    cellpadding="3"
                                    cellspacing="6"
                                    id="tablacuatropilares"
                                  >
                                    <tbody>
                                      <tr>
                                        <td className="xhundido">
                                          Año (desde 1900)
                                          <p>
                                            <input
                                              name="nnin"
                                              size="5"
                                              value={defaultYear}
                                              className="form-control"
                                              onChange={handleSetDefaultYear}
                                            />
                                          </p>
                                        </td>
                                        <td className="xhundido">
                                          <div className="styled-select">
                                            Sexo
                                            <p>
                                              <select
                                                name="BBB"
                                                className="form-select"
                                              >
                                                <option
                                                  selected="selected"
                                                  value="-1"
                                                >
                                                  Femenino
                                                </option>
                                                <option value="1">
                                                  Masculino
                                                </option>
                                              </select>
                                            </p>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="xhundido">
                                          <p>Mes </p>
                                          <select
                                            name="nyuet"
                                            className="form-select"
                                          >
                                            <option
                                              selected="selected"
                                              value="1"
                                            >
                                              {" "}
                                              Enero{" "}
                                            </option>
                                            <option value="2"> Febrero </option>
                                            <option value="3"> Marzo </option>
                                            <option value="4"> Abril </option>
                                            <option value="5"> Mayo </option>
                                            <option value="6"> Junio </option>
                                            <option value="7"> Julio </option>
                                            <option value="8"> Agosto </option>
                                            <option value="9">
                                              {" "}
                                              Septiembre{" "}
                                            </option>
                                            <option value="10">
                                              {" "}
                                              Octubre{" "}
                                            </option>
                                            <option value="11">
                                              {" "}
                                              Noviembre{" "}
                                            </option>
                                            <option value="12">
                                              {" "}
                                              Diciembre{" "}
                                            </option>
                                          </select>
                                        </td>
                                        <td className="xhundido">
                                          <p>Día </p>

                                          <select
                                            name="nyat"
                                            className="form-select"
                                          >
                                            <option
                                              selected="selected"
                                              value="1"
                                            >
                                              {" "}
                                              01{" "}
                                            </option>
                                            <option value="2"> 02 </option>
                                            <option value="3"> 03 </option>
                                            <option value="4"> 04 </option>
                                            <option value="5"> 05 </option>
                                            <option value="6"> 06 </option>
                                            <option value="7"> 07 </option>
                                            <option value="8"> 08 </option>
                                            <option value="9"> 09 </option>
                                            <option value="10"> 10 </option>
                                            <option value="11"> 11 </option>
                                            <option value="12"> 12 </option>
                                            <option value="13"> 13 </option>
                                            <option value="14"> 14 </option>
                                            <option value="15"> 15 </option>
                                            <option value="16"> 16 </option>
                                            <option value="17"> 17 </option>
                                            <option value="18"> 18 </option>
                                            <option value="19"> 19 </option>
                                            <option value="20"> 20 </option>
                                            <option value="21"> 21 </option>
                                            <option value="22"> 22 </option>
                                            <option value="23"> 23 </option>
                                            <option value="24"> 24 </option>
                                            <option value="25"> 25 </option>
                                            <option value="26"> 26 </option>
                                            <option value="27"> 27 </option>
                                            <option value="28"> 28 </option>
                                            <option value="29"> 29 </option>
                                            <option value="30"> 30 </option>
                                            <option value="31"> 31 </option>
                                          </select>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="xhundido">
                                          <p>Hora </p>
                                          <p>
                                            <select
                                              name="nsee"
                                              className="form-select"
                                            >
                                              <option
                                                selected="selected"
                                                value="0"
                                              >
                                                {" "}
                                                00{" "}
                                              </option>
                                              <option value="1"> 01 </option>
                                              <option value="2"> 02 </option>
                                              <option value="3"> 03 </option>
                                              <option value="4"> 04 </option>
                                              <option value="5"> 05 </option>
                                              <option value="6"> 06 </option>
                                              <option value="7"> 07 </option>
                                              <option value="8"> 08 </option>
                                              <option value="9"> 09 </option>
                                              <option value="10"> 10 </option>
                                              <option value="11"> 11 </option>
                                              <option value="12"> 12 </option>
                                              <option value="13"> 13 </option>
                                              <option value="14"> 14 </option>
                                              <option value="15"> 15 </option>
                                              <option value="16"> 16 </option>
                                              <option value="17"> 17 </option>
                                              <option value="18"> 18 </option>
                                              <option value="19"> 19 </option>
                                              <option value="20"> 20 </option>
                                              <option value="21"> 21 </option>
                                              <option value="22"> 22 </option>
                                              <option value="23"> 23 </option>
                                            </select>
                                          </p>
                                        </td>
                                        <td className="xhundido">
                                          Minutos
                                          <p>
                                            <select
                                              name="nfen"
                                              className="form-select"
                                            >
                                              <option
                                                selected="selected"
                                                value="0"
                                              >
                                                {" "}
                                                00{" "}
                                              </option>
                                              <option value="1"> 01 </option>
                                              <option value="2"> 02 </option>
                                              <option value="3"> 03 </option>
                                              <option value="4"> 04 </option>
                                              <option value="5"> 05 </option>
                                              <option value="6"> 06 </option>
                                              <option value="7"> 07 </option>
                                              <option value="8"> 08 </option>
                                              <option value="9"> 09 </option>
                                              <option value="10"> 10 </option>
                                              <option value="11"> 11 </option>
                                              <option value="12"> 12 </option>
                                              <option value="13"> 13 </option>
                                              <option value="14"> 14 </option>
                                              <option value="15"> 15 </option>
                                              <option value="16"> 16 </option>
                                              <option value="17"> 17 </option>
                                              <option value="18"> 18 </option>
                                              <option value="19"> 19 </option>
                                              <option value="20"> 20 </option>
                                              <option value="21"> 21 </option>
                                              <option value="22"> 22 </option>
                                              <option value="23"> 23 </option>
                                              <option value="24"> 24 </option>
                                              <option value="25"> 25 </option>
                                              <option value="26"> 26 </option>
                                              <option value="27"> 27 </option>
                                              <option value="28"> 28 </option>
                                              <option value="29"> 29 </option>
                                              <option value="30"> 30 </option>
                                              <option value="31"> 31 </option>
                                              <option value="32"> 32 </option>
                                              <option value="33"> 33 </option>
                                              <option value="34"> 34 </option>
                                              <option value="35"> 35 </option>
                                              <option value="36"> 36 </option>
                                              <option value="37"> 37 </option>
                                              <option value="38"> 38 </option>
                                              <option value="39"> 39 </option>
                                              <option value="40"> 40 </option>
                                              <option value="41"> 41 </option>
                                              <option value="42"> 42 </option>
                                              <option value="43"> 43 </option>
                                              <option value="44"> 44 </option>
                                              <option value="45"> 45 </option>
                                              <option value="46"> 46 </option>
                                              <option value="47"> 47 </option>
                                              <option value="48"> 48 </option>
                                              <option value="49"> 49 </option>
                                              <option value="50"> 50 </option>
                                              <option value="51"> 51 </option>
                                              <option value="52"> 52 </option>
                                              <option value="53"> 53 </option>
                                              <option value="54">54</option>
                                              <option value="55">55</option>
                                              <option value="56">56</option>
                                              <option value="57">57</option>
                                              <option value="58">58</option>
                                              <option value="59">59</option>
                                            </select>
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <p>
                                    <br />
                                    <input
                                      onClick={handleClickSubmit}
                                      value="CALCULAR"
                                      type="button"
                                      className="boton-calcular"
                                    />
                                  </p>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm">
                        <div className="col1 paper">
                          <div className="in">
                            <span className="stud  b_l"></span>
                            <span className="stud  t_l"></span>
                            <span className="stud  t_r"></span>
                            <span className="stud  b_r"></span>
                            <span className="tl"></span> <span className="t"></span>{" "}
                            <span className="tr"></span> <span className="r"></span>
                            <span className="br"></span> <span className="b"></span>{" "}
                            <span className="bl"></span> <span className="l"></span>
                            <h5 className="text-center">
                              {" "}
                              Tus Cuatro Pilares son...{" "}
                            </h5>
                            <table cellspacing="6" className="table-4pilares">
                              <tbody>
                                <tr>
                                  <td align="right"></td>
                                  <td className="col2">
                                    <strong>Hora</strong>
                                  </td>
                                  <td className="col2">
                                    <strong>Día</strong>
                                  </td>
                                  <td className="col2">
                                    <strong>Mes</strong>
                                  </td>
                                  <td className="col2">
                                    <strong>Año</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <th align="right">Elemento</th>
                                  <td className="col2">
                                    <p id="hs"></p>
                                  </td>
                                  <td className="col2">
                                    <p id="ds"></p>

                                    <p>
                                      <strong>
                                        Elemento
                                        <br />
                                        maestro
                                      </strong>
                                    </p>
                                  </td>
                                  <td className="col2">
                                    <p id="ms"></p>
                                  </td>
                                  <td className="col2">
                                    <p id="ys"></p>
                                  </td>
                                </tr>
                                <tr>
                                  <th align="right">
                                    Animal <br />
                                    Celestial
                                  </th>
                                  <td height="47" className="col2">
                                    <p id="hb"></p>
                                  </td>
                                  <td height="47" className="col2">
                                    <p id="db"></p>
                                  </td>
                                  <td height="47" className="col2">
                                    <p id="mb"></p>
                                  </td>
                                  <td height="47" className="col2">
                                    <p id="yb"></p>
                                  </td>
                                </tr>
                                <tr>
                                  <th align="right">
                                    Elemento
                                    <br />
                                    Principal
                                  </th>
                                  <td height="40" className="col2">
                                    <p id="hs1"></p>
                                  </td>
                                  <td height="40" className="col2">
                                    <p id="ds1"></p>
                                  </td>
                                  <td height="40" className="col2">
                                    <p id="ms1"></p>
                                  </td>
                                  <td height="40" className="col2">
                                    <p id="ys1"></p>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody>
                                <tr>
                                  <th align="right">
                                    Elementos <br />
                                    Ocultos
                                  </th>
                                  <td height="41" className="col2">
                                    <p id="hs2"></p>

                                    <p id="hs3"></p>
                                  </td>
                                  <td height="56" className="col2">
                                    <p id="ds2"></p>

                                    <p id="ds3"></p>
                                  </td>
                                  <td height="56" className="col2">
                                    <p id="ms2"></p>

                                    <p id="ms3"></p>
                                  </td>
                                  <td height="56" className="col2">
                                    <p id="ys2"></p>

                                    <p id="ys3"></p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <h5 className="guapersonal-txt"> Gua Personal</h5>
                            <table cellspacing="6" className="table-4pilares">
                              <tbody>
                                <tr>
                                  <td>
                                    <p>
                                      Ming Gua: <span id="mng"></span>
                                    </p>
                                  </td>
                                  <td>
                                    <p id="strgr"></p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <p>
                              <br />

                              <p id="comment1"></p>
                            </p>
                            <p>
                              <p id="comment2"></p>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clearboth mb-4"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="clearboth"></div>
    </div>
  );
};

export default CuatroPilares;
