import React, { useState } from "react";
import logo from "../images/fengshui-logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Header = (props) => {
  const [offCanvas, setOffCanvas] = useState(false);
  const [darkScreen, setDarkScreen] = useState(false);

  const handleClickButton = () => {
    props.handleSetScreen();
    setOffCanvas(false);
    setDarkScreen(false);
  };

  const handleShowOffCanvas = ()=>{
    setOffCanvas(true);
    setDarkScreen(true);
  }

  const handleHideOffCanvas = ()=>{
    setOffCanvas(false);
    setDarkScreen(false);
  }

  return (
    <div>
        <span class={`screen-darken ${darkScreen ? 'active' : ''}`}></span>
        <div className="navbar-mobile mt-2">
            <div className="container">
                <a className="navbar-brand" href="https://fengshui-mundo.com">
                    <img src={logo} width="30" height="30" className="d-inline-block align-top" alt="Feng Shui Mundo" />
                    <strong className="txt-logo-mobile"> Feng Shui Mundo</strong>
                </a>
                <button data-trigger="navbar_main" className="navbar-toggler float-end mt-2" type="button" onClick={handleShowOffCanvas}>
                    <FontAwesomeIcon icon={faBars} />
                </button>
            </div> 
        </div>
        
      <nav id="navbar_main" className={`mobile-offcanvas navbar navbar-expand-lg navbar-light navbar-fengshui ${offCanvas ? 'show' : ''}` }>
        <div className="container">
          <div className="offcanvas-header">
            <button className="btn-close float-end mt-2" onClick={handleHideOffCanvas}>
                <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <a className="navbar-brand" href="https://fengshui-mundo.com">
            <img src={logo} width="30" height="30" className="d-inline-block align-top" alt="Feng Shui Mundo" />
            <strong> Feng Shui Mundo</strong>
          </a>

          <ul class="navbar-nav">
            <li className="nav-item botones-menu">
              {props.screen === 2 ? (
                <button type="button" className="btn btn-dark" onClick={handleClickButton}>
                  Calculador de 4 pilares
                </button>
              ) : (
                <button type="button" className="btn btn-dark" onClick={handleClickButton}>
                  Calculador de estrellas volantes
                </button>
              )}
            </li>
            <li className="nav-item botones-menu ml-4">
              <a href="http://fengshui-mundo.com">
                <button type="button" className="btn btn-dark">
                    Volver a Feng Shui Mundo
                </button>
              </a>
            </li>
            
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;
