import React, {useState} from 'react';
import './styles/App.css';
import EstrellasVolantes from './views/EstrellasVolantes';
import CuatroPilares from './views/CuatroPilares';
import Header from './components/Header';
import LinkTablas from './views/LinksTablas';


const App = ()=> {
  const [screen, setScreen] = useState(1);

  const handleSetScreen = ()=>{
    if(screen === 1){
      setScreen(2);
    }else{
      setScreen(1);
    }
  }

  const content = screen === 1 ? <CuatroPilares /> : <EstrellasVolantes />
  return (
    <div>
      <Header 
        handleSetScreen={handleSetScreen}
        screen={screen}
      />
      <LinkTablas />
      {content}
    </div>
  );
}

export default App;
