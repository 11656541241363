// Cálculo cuatro pilares

import $ from 'jquery';

function compute(form) {
    if (form.nnin.value == "") {
    alert("Solo para años desde 1900 en adelante.");
    form.nnin.focus();
    form.nnin.select();
    return;
    }
    if (eval(form.nnin.value)<1900) {
    alert("Solo para años desde 1900 en adelante..");
    form.nnin.focus();
    form.nnin.select();
    return;
    }
    var gon = Array("Agua Yin", "Madera Yang", "Madera Yin", "Fuego Yang", "Fuego Yin", "Tierra Yang", "Tierra Yin",
    "Metal Yang", "Metal Yin", "Agua Yang", "Agua Yin", "Madera Yang");
    var ji = Array( "Cerdo", "Rata", "Buey", "Tigre", "Liebre", "Dragon", "Serpiente",
    "Caballo", "Cabra", "Mono", "Gallo", "Perro", "Cerdo", "Rata");
    
    var MM=eval(form.nyuet.value);
    var DD=eval(form.nyat.value);
    var YY=eval(form.nnin.value);
    var HR=eval(form.nsee.value);
    var MN=eval(form.nfen.value);
    var GEN=eval(form.BBB.value);
    
    
    /* with (Math){ */
    var AAA = 1;
    if (YY <= 1585) AAA = 0;
    var JD = -1 * Math.floor(7 * (Math.floor((MM + 9) / 12) + YY) / 4);
    var S = 1;
    if ((MM - 9)<0) S=-1;
    var A = Math.abs(MM - 9);
    var J1 = Math.floor(YY + S * Math.floor(A / 7));
    J1 = -1 * Math.floor((Math.floor(J1 / 100) + 1) * 3 / 4);
    JD = JD + Math.floor(275 * MM / 9) + DD + (AAA * J1);
    JD = JD + 1721027 + 2 * AAA + 367 * YY - 0.5;
    var JZJD =  JD + (HR / 24);
    
    var T = (JD - 2451545.0 ) / 36525;
    var d = 2*Math.PI/360;
    var M = 357.52910 + 35999.05030*T - 0.0001559*T*T - 0.00000048*T*T*T;
    var L0 = 280.46645 + 36000.76983*T + 0.0003032*T*T;
    var DL = (1.914600 - 0.004817*T - 0.000014*T*T)*Math.sin(d*M);
    DL = DL+ (0.019993 - 0.000101*T)*Math.sin(d*2*M) + 0.000290*Math.sin(d*3*M);
    var L = L0 + DL;
    /* } */
    
    var YEFL=0;
    for(var i=0;L>360;i++){
    L=L-360;
    }
    for(var i=0;L<0;i++){
    L=L+360;
    }
    if ((L<315) && (MM==1 || MM==2)){
    var ys0=YY-4;
    var yb0=YY-4;
    YEFL=1;
    }
    else {
    ys0=YY-3;
    yb0=YY-3;
    }
    for(var i=0;ys0>10;i++){
    ys0=ys0-10;
    }
    var ys=gon[ys0];
    for(var i=0;yb0>12;i++){
    yb0=yb0-12;
    }
    if((ys0==0 || ys0==2 || ys0==4 || ys0==6 || ys0==8 || ys0==10)){
    var FW=-1*GEN;
    }
    else{
    FW=1*GEN;
    }
    var yb=ji[yb0];
    var ms0 = 1;
    for (var i=0;i<6;i++){
    if ((ys0==i) || (ys0==i+5)) {
    ms0=ms0+ (i * 2);
    if (ms0>10) {
    ms0=ms0-10;
    }
    }
    }
    $("#comment1").html(""); //form.comment1.value = "";
    $("#comment2").html(""); //form.comment2.value = "";
    var str00 = "Su fecha de nacimiento está muy cerca del cambio de estación, ";
    var str01 = "Se requiere un estudio especializado para un cálculo preciso";
    var str1 = "";
    var str2 = "";
    if (L>314.95 && L<315.05){
    str1 = str00 + "Li Chi: Primavera.";
    str2 = str01;
    }
    if (L>344.95 && L<345.05){
    str1 = str00 + "Despertar de los Insectos.";
    str2 = str01;
    }
    if (L>14.95 && L<15.05){
    str1 = str00 + "Claro y Brillante.";
    str2 = str01;
    }
    if (L>44.95 && L<45.05){
    str1 = str00 + "Comienzo del Verano.";
    str2 = str01;
    }
    if (L>74.95 && L<75.05){
    str1 = str00 + "Plantar Cultivos.";
    str2 = str01;
    }
    if (L>104.95 && L<105.05){
    str1 = str00 + "Pequeño Calor.";
    str2 = str01;
    }
    if (L>134.95 && L<135.05){
    str1 = str00 + "Comienzo del Otoño";
    str2 = str01;
    }
    if (L>164.95 && L<165.05){
    str1 = str00 + "Rocío Blanco.";
    str2 = str01;
    }
    if (L>194.95 && L<195.05){
    str1 = str00 + "Rocío Frío.";
    str2 = str01;
    }
    if (L>224.95 && L<225.05){
    str1 = str00 + "Comienzo del Invierno.";
    str2 = str01;
    }
    if (L>254.95 && L<255.05){
    str1 = str00 + "Gran Nieve.";
    str2 = str01;
    }
    if (L>284.95 && L<285.05){
    str1 = str00 + "Pequeño Frío.";
    str2 = str01;
    }
    if ((L==315 || L>315) && (L<345)){
    var mb = ji[3];
    var lpb0 = 3;
    var cb = ji[6];
    var nsb=2;
    if( FW==1){
    var LP = ((345 - L)/3);
    }
    else{
    LP = ((L - 315)/3);
    }
    }
    if ((L==345 || L>345) || (L<15)){
    mb = ji[4];
    lpb0 = 4;
    cb = ji[7];
    nsb=3;
    ms0=ms0+ 1;
    if( FW==1){
    LP = ((375 - L)/3);
    }
    else{
    LP =((L - 345)/3);
    }
    if (LP>11){
    LP = LP - 120;
    }
    if (LP<0){
    LP = LP + 120;
    }
    }
    if ((L==15 || L>15) && (L<45)){
    mb = ji[5];
    lpb0 = 5;
    cb = ji[8];
    nsb=4;
    ms0=ms0+ 2;
    if( FW==1){
    LP = ((45 - L)/3);
    }
    else{
    LP = ((L - 15)/3);
    }
    }
    if ((L==45 || L>45) && (L<75)){
    mb = ji[6];
    lpb0 = 6;
    cb = ji[9];
    nsb=5;
    ms0=ms0+ 3;
    
    if( FW==1){
    LP = ((75 - L)/3);
    }
    else{
    LP = ((L - 45)/3);
    }
    }
    if ((L==75 || L>75) && (L<105)){
    mb = ji[7];
    lpb0 = 7;
    cb = ji[10];
    nsb=6;
    ms0=ms0+ 4;
    if( FW==1){
    LP = ((105 - L)/3);
    }
    else{
    LP = ((L - 75)/3);
    }
    }
    if ((L==105 || L>105) && (L<135)){
    mb = ji[8];
    lpb0 = 8;
    cb = ji[11];
    nsb=7;
    ms0=ms0+ 5;
    if( FW==1){
    LP = ((135 - L)/3);
    }
    else{
    LP = ((L - 105)/3);
    }
    }
    if ((L==135 || L>135) && (L<165)){
    mb = ji[9];
    lpb0 = 9;
    cb = ji[12];
    nsb=8;
    ms0=ms0+ 6;
    if( FW==1){
    LP = ((165 - L)/3);
    }
    else{
    LP = ((L - 135)/3);
    }
    }
    if ((L==165 || L>165) && (L<195)){
    mb = ji[10];
    lpb0 = 10;
    cb = ji[1];
    nsb=9;
    ms0=ms0+ 7;
    if( FW==1){
    LP = ((195 - L)/3);
    }
    else{
    LP = ((L - 165)/3);
    }
    }
    if ((L==195 || L>195) && (L<225)){
    mb = ji[11];
    lpb0 = 11;
    cb = ji[2];
    nsb=10;
    ms0=ms0+ 8;
    if( FW==1){
    LP = ((225 - L)/3);
    }
    else{
    LP = ((L - 195)/3);
    }
    }
    if ((L==225 || L>225) && (L<255)){
    mb = ji[12];
    lpb0 = 12;
    cb = ji[3];
    nsb=11;
    ms0=ms0+ 9;
    if( FW==1){
    LP = ((255 - L)/3);
    }
    else{
    LP = ((L - 225)/3);
    }
    }
    if ((L==255 || L>255) && (L<285)){
    mb = ji[1];
    lpb0 = 1;
    cb = ji[4];
    nsb=0;
    ms0=ms0+ 10;
    if( FW==1){
    LP = ((285 - L)/3);
    }
    else{
    LP = ((L - 255)/3);
    }
    }
    if ((L==285 || L>285) && (L<315)){
    mb = ji[2];
    lpb0 = 2;
    cb = ji[5];
    nsb=1;
    ms0=ms0+ 11;
    if( FW==1){
    LP = ((315 - L)/3);
    }
    else{
    LP = ((L - 285)/3);
    }
    }
    if (ms0>10) {
    ms0=ms0-10;
    }
    
    /* with(Math){ */
    var ms = gon[ms0];
    var cs = gon[ms0+1];
    var lps0 = ms0;
    var ds0="" + Math.floor(JZJD+0.5);
    var ds=gon[ds0.substring(6,7)];
    var db0=Math.floor(JZJD - 12*Math.floor((JZJD+0.5)/12)+0.5)+2;
    var db = ji[db0];
    var hs0=1;
    var hs1=0;
    /* } */

    for (var i=1;i<5;i++){
    if ((eval(ds0.substring(6,7))==i) || (eval(ds0.substring(6,7))==i+5)) {
    hs1=hs0;
    }
    hs0=hs0+2;
    }
    if ((eval(ds0.substring(6,7))==0) || (eval(ds0.substring(6,7))==5)) {
    hs1=9;
    }
    if ((HR==23) || (HR>23 && HR<24)) {
    hs1=hs1+2;
    }
    if (((HR==23) || (HR>23 && HR<24)) || ((HR==parseInt('00')) || (HR>0 && HR<1) || (HR==24))) {
    var hb=ji[1];
    }
    var hb0=2;
    for (var i=1;i<23;i++){
    if ((HR==i) || (HR>i && HR<i+2)) {
    hb=ji[hb0];
    hs1=hs1+hb0-1;
    }
    i=i+1;
    hb0=hb0+1;
    }
    if (hs1>10) {
    hs1=hs1-10;
    }
    
    /* with(Math){ */
    var hs=gon[hs1];
    LP = (Math.floor(LP*100)/100);
    /* } */
    
    if (hb=="Cerdo"){
    var his1=gon[9];
    var his2=gon[1];
    var his3="";
    var hbi=1;
    }
    if (hb=="Rata"){
    his1=gon[0];
    his2="";
    his3="";
    hbi=2;
    }
    if (hb=="Buey"){
    his1=gon[6];
    his2=gon[0];
    his3=gon[8];
    hbi=3;
    }
    if (hb=="Tigre"){
    his1=gon[1];
    his2=gon[3];
    his3=gon[5];
    hbi=4;
    }
    if (hb=="Liebre"){
    his1=gon[2];
    his2="";
    his3="";
    hbi=5;
    }
    if (hb=="Dragon"){
    his1=gon[5];
    his2=gon[2];
    his3=gon[0];
    hbi=6;
    }
    if (hb=="Serpiente"){
    his1=gon[3];
    his2=gon[5];
    his3=gon[7];
    hbi=7;
    }
    if (hb=="Caballo"){
    his1=gon[4];
    his2=gon[6];
    his3="";
    hbi=8;
    }
    if (hb=="Cabra"){
    his1=gon[6];
    his2=gon[4];
    his3=gon[2];
    hbi=9;
    }
    if (hb=="Mono"){
    his1=gon[7];
    his2=gon[9];
    his3=gon[5];
    hbi=10;
    }
    if (hb=="Gallo"){
    his1=gon[8];
    his2="";
    his3="";
    hbi=11;
    }
    if (hb=="Perro"){
    his1=gon[5];
    his2=gon[8];
    his3=gon[4];
    hbi=12;
    }
    if (db=="Cerdo"){
    var dis1=gon[9];
    var dis2=gon[1];
    var dis3="";
    }
    if (db=="Rata"){
    dis1=gon[0];
    dis2="";
    dis3="";
    }
    if (db=="Buey"){
    dis1=gon[6];
    dis2=gon[0];
    dis3=gon[8];
    }
    if (db=="Tigre"){
    dis1=gon[1];
    dis2=gon[3];
    dis3=gon[5];
    }
    if (db=="Liebre"){
    dis1=gon[2];
    dis2="";
    dis3="";
    }
    if (db=="Dragon"){
    dis1=gon[5];
    dis2=gon[2];
    dis3=gon[0];
    }
    if (db=="Serpiente"){
    dis1=gon[3];
    dis2=gon[5];
    dis3=gon[7];
    }
    if (db=="Caballo"){
    dis1=gon[4];
    dis2=gon[6];
    dis3="";
    }
    if (db=="Cabra"){
    dis1=gon[6];
    dis2=gon[4];
    dis3=gon[2];
    }
    if (db=="Mono"){
    dis1=gon[7];
    dis2=gon[9];
    dis3=gon[5];
    }
    if (db=="Gallo"){
    dis1=gon[8];
    dis2="";
    dis3="";
    }
    if (db=="Perro"){
    dis1=gon[5];
    dis2=gon[8];
    dis3=gon[4];
    }
    if (mb=="Cerdo"){
    var mis1=gon[9];
    var mis2=gon[1];
    var mis3="";
    var mbi=1;
    }
    if (mb=="Rata"){
    mis1=gon[0];
    mis2="";
    mis3="";
    mbi=2;
    }
    if (mb=="Buey"){
    mis1=gon[6];
    mis2=gon[0];
    mis3=gon[8];
    mbi=3;
    }
    if (mb=="Tigre"){
    mis1=gon[1];
    mis2=gon[3];
    mis3=gon[5];
    mbi=4;
    }
    if (mb=="Liebre"){
    mis1=gon[2];
    mis2="";
    mis3="";
    mbi=5;
    }
    if (mb=="Dragon"){
    mis1=gon[5];
    mis2=gon[2];
    mis3=gon[0];
    mbi=6;
    }
    if (mb=="Serpiente"){
    mis1=gon[3];
    
    mis2=gon[5];
    mis3=gon[7];
    mbi=7;
    }
    if (mb=="Caballo"){
    mis1=gon[4];
    mis2=gon[6];
    mis3="";
    mbi=8;
    }
    if (mb=="Cabra"){
    mis1=gon[6];
    mis2=gon[4];
    mis3=gon[2];
    mbi=9;
    }
    if (mb=="Mono"){
    mis1=gon[7];
    mis2=gon[9];
    mis3=gon[5];
    mbi=10;
    }
    if (mb=="Gallo"){
    mis1=gon[8];
    mis2="";
    mis3="";
    mbi=11;
    }
    if (mb=="Perro"){
    mis1=gon[5];
    mis2=gon[8];
    mis3=gon[4];
    mbi=12;
    }
    if (yb=="Cerdo"){
    var yis1=gon[9];
    var yis2=gon[1];
    var yis3="";
    }
    if (yb=="Rata"){
    yis1=gon[0];
    yis2="";
    yis3="";
    }
    if (yb=="Buey"){
    yis1=gon[6];
    yis2=gon[0];
    yis3=gon[8];
    }
    if (yb=="Tigre"){
    yis1=gon[1];
    yis2=gon[3];
    yis3=gon[5];
    }
    if (yb=="Liebre"){
    yis1=gon[2];
    yis2="";
    yis3="";
    }
    if (yb=="Dragon"){
    yis1=gon[5];
    yis2=gon[2];
    yis3=gon[0];
    }
    if (yb=="Serpiente"){
    yis1=gon[3];
    yis2=gon[5];
    yis3=gon[7];
    }
    if (yb=="Caballo"){
    yis1=gon[4];
    yis2=gon[6];
    yis3="";
    }
    if (yb=="Cabra"){
    yis1=gon[6];
    yis2=gon[4];
    yis3=gon[2];
    }
    if (yb=="Mono"){
    yis1=gon[7];
    yis2=gon[9];
    yis3=gon[5];
    }
    if (yb=="Gallo"){
    yis1=gon[8];
    yis2="";
    yis3="";
    }
    if (yb=="Perro"){
    yis1=gon[5];
    yis2=gon[8];
    yis3=gon[4];
    }
    if (cb=="Cerdo"){
    var cis1=gon[9];
    var cis2=gon[1];
    var cis3="";
    }
    if (cb=="Rata"){
    cis1=gon[0];
    cis2="";
    cis3="";
    }
    if (cb=="Buey"){
    cis1=gon[6];
    cis2=gon[0];
    cis3=gon[8];
    }
    if (cb=="Tigre"){
    cis1=gon[1];
    cis2=gon[3];
    cis3=gon[5];
    }
    if (cb=="Liebre"){
    cis1=gon[2];
    cis2="";
    cis3="";
    }
    if (cb=="Dragon"){
    cis1=gon[5];
    cis2=gon[2];
    cis3=gon[0];
    }
    if (cb=="Serpiente"){
    cis1=gon[3];
    cis2=gon[5];
    cis3=gon[7];
    }
    if (cb=="Caballo"){
    cis1=gon[4];
    cis2=gon[6];
    cis3="";
    }
    if (cb=="Cabra"){
    cis1=gon[6];
    cis2=gon[4];
    cis3=gon[2];
    }
    if (cb=="Mono"){
    cis1=gon[7];
    cis2=gon[9];
    cis3=gon[5];
    }
    if (cb=="Gallo"){
    cis1=gon[8];
    cis2="";
    cis3="";
    }
    if (cb=="Perro"){
    cis1=gon[5];
    cis2=gon[8];
    cis3=gon[4];
    }
    var lbi=34-mbi-hbi;
    for(var i=0;lbi>12;i++){
    lbi=lbi-12;
    }
    var lb = ji[lbi];
    if (lb=="Cerdo"){
    var lis1=gon[9];
    var lis2=gon[1];
    var lis3="";
    }
    if (lb=="Rata"){
    lis1=gon[0];
    lis2="";
    lis3="";
    }
    if (lb=="Buey"){
    lis1=gon[6];
    lis2=gon[0];
    lis3=gon[8];
    }
    if (lb=="Tigre"){
    lis1=gon[1];
    lis2=gon[3];
    lis3=gon[5];
    }
    if (lb=="Liebre"){
    lis1=gon[2];
    lis2="";
    lis3="";
    }
    if (lb=="Dragon"){
    lis1=gon[5];
    lis2=gon[2];
    lis3=gon[0];
    }
    if (lb=="Serpiente"){
    lis1=gon[3];
    lis2=gon[5];
    lis3=gon[7];
    }
    if (lb=="Caballo"){
    lis1=gon[4];
    lis2=gon[6];
    lis3="";
    }
    if (lb=="Cabra"){
    lis1=gon[6];
    lis2=gon[4];
    lis3=gon[2];
    }
    if (lb=="Mono"){
    lis1=gon[7];
    lis2=gon[9];
    lis3=gon[5];
    }
    if (lb=="Gallo"){
    lis1=gon[8];
    lis2="";
    lis3="";
    }
    if (lb=="Perro"){
    lis1=gon[5];
    lis2=gon[8];
    lis3=gon[4];
    }
    if (ys0==2){
    var lsi=lbi+2;
    }
    if (ys0==7){
    lsi=lbi+2;
    }
    if (ys0==3){
    lsi=lbi+4;
    }
    if (ys0==8){
    lsi=lbi+4;
    }
    if (ys0==4){
    lsi=lbi+6;
    }
    if (ys0==9){
    lsi=lbi+6;
    }
    if (ys0==5){
    lsi=lbi+8;
    }
    if (ys0==10){
    lsi=lbi+8;
    }
    if (ys0==1){
    lsi=lbi;
    }
    if (ys0==6){
    lsi=lbi;
    }
    for(var i=0;lsi>10;i++){
    lsi=lsi-10;
    }
    var ls=gon[lsi];
    lpb0 = lpb0 + FW;
    if ((lpb0>12) || (lpb0<0)){
    lpb0 = lpb0 - 12*FW;
    }
    var lpb1 = ji[lpb0];
    lpb0 = lpb0 + FW;
    if ((lpb0>12) || (lpb0<0)){
    lpb0 = lpb0 - 12*FW;
    }
    var lpb2 = ji[lpb0];
    lpb0 = lpb0 + FW;
    if ((lpb0>12) || (lpb0<0)){
    lpb0 = lpb0 - 12*FW;
    }
    var lpb3 = ji[lpb0];
    lpb0 = lpb0 + FW;
    if ((lpb0>12) || (lpb0<0)){
    lpb0 = lpb0 - 12*FW;
    }
    var lpb4 = ji[lpb0];
    lpb0 = lpb0 + FW;
    if ((lpb0>12) || (lpb0<0)){
    lpb0 = lpb0 - 12*FW;
    }
    var lpb5 = ji[lpb0];
    lpb0 = lpb0 + FW;
    if ((lpb0>12) || (lpb0<0)){
    lpb0 = lpb0 - 12*FW;
    }
    var lpb6 = ji[lpb0];
    lpb0 = lpb0 + FW;
    if ((lpb0>12) || (lpb0<0)){
    lpb0 = lpb0 - 12*FW;
    }
    var lpb7 = ji[lpb0];
    lpb0 = lpb0 + FW;
    if ((lpb0>12) || (lpb0<0)){
    lpb0 = lpb0 - 12*FW;
    }
    var lpb8 = ji[lpb0];
    lpb0 = lpb0 + FW;
    if ((lpb0>12) || (lpb0<0)){
    lpb0 = lpb0 - 12*FW;
    }
    var lpb9 = ji[lpb0];
    lpb0 = lpb0 + FW;
    if ((lpb0>12) || (lpb0<0)){
    lpb0 = lpb0 - 12*FW;
    }
    var lpb10 = ji[lpb0];
    lpb0 = lpb0 + FW;
    if ((lpb0>12) || (lpb0<0)){
    lpb0 = lpb0 - 12*FW;
    }
    lps0 = lps0 + FW;
    if ((lps0>10) || (lps0<0)){
    lps0 = lps0 - 10*FW;
    }
    var lps1 = gon[lps0];
    lps0 = lps0 + FW;
    if ((lps0>10) || (lps0<0)){
    lps0 = lps0 - 10*FW;
    }
    var lps2 = gon[lps0];
    lps0 = lps0 + FW;
    if ((lps0>10) || (lps0<0)){
    lps0 = lps0 - 10*FW
    }
    var lps3 = gon[lps0];
    lps0 = lps0 + FW;
    if ((lps0>10) || (lps0<0)){
    lps0 = lps0 - 10*FW;
    }
    var lps4 = gon[lps0];
    lps0 = lps0 + FW;
    if ((lps0>10) || (lps0<0)){
    lps0 = lps0 - 10*FW;
    }
    var lps5 = gon[lps0]
    lps0 = lps0 + FW
    if ((lps0>10) || (lps0<0)){
    lps0 = lps0 - 10*FW
    }
    var lps6 = gon[lps0];
    lps0 = lps0 + FW;
    if ((lps0>10) || (lps0<0)){
    lps0 = lps0 - 10*FW;
    }
    var lps7 = gon[lps0];
    lps0 = lps0 + FW;
    if ((lps0>10) || (lps0<0)){
    lps0 = lps0 - 10*FW;
    }
    var lps8 = gon[lps0];
    lps0 = lps0 + FW;
    if ((lps0>10) || (lps0<0)){
    lps0 = lps0 - 10*FW;
    }
    var lps9 = gon[lps0];
    lps0 = lps0 + FW;
    if ((lps0>10) || (lps0<0)){
    lps0 = lps0 - 10*FW;
    }
    var lps10 = gon[lps0];
    lps0 = lps0 + FW;
    if ((lps0>10) || (lps0<0)){
    lps0 = lps0 - 10*FW;
    }
    
    $("#ys").html(ys); //form.ys.value = ys ;
    $("#ms").html(ms); //form.ms.value= ms ;
    $("#ds").html(ds); //form.ds.value = ds ;
    $("#hs").html(hs); //form.hs.value = hs ;
    
    
    
    $("#yb").html(yb); //form.yb.value = yb;
    $("#mb").html(mb); //form.mb.value= mb;
    $("#db").html(db); //form.db.value = db;
    $("#hb").html(hb); //form.hb.value = hb;
    
    
    if (!str1 == "") str1="<h5>Observaciones</h5>" + str1;
    $("#comment1").html(str1); //form.comment1.value = str1;
    $("#comment2").html(str2); //form.comment2.value = str2;
    
    $("#hs1").html(his1); //form.hs1.value = his1;
    $("#hs2").html(his2); //form.hs2.value = his2;
    $("#hs3").html(his3); //form.hs3.value = his3;
    $("#ds1").html(dis1); //form.ds1.value = dis1;
    $("#ds2").html(dis2); //form.ds2.value = dis2;
    $("#ds3").html(dis3); //form.ds3.value = dis3;
    $("#ms1").html(mis1); //form.ms1.value = mis1;
    $("#ms2").html(mis2); //form.ms2.value = mis2;
    $("#ms3").html(mis3); //form.ms3.value = mis3;
    $("#ys1").html(yis1); //form.ys1.value = yis1;
    $("#ys2").html(yis2); //form.ys2.value = yis2;
    $("#ys3").html(yis3); //form.ys3.value = yis3;
    
    
    
    
    
    
    
    
    
    
    
    var nsky=((3007 -YY)%9)+1;
    if(YEFL==1){
    nsky=nsky+1;
    }
    if(nsky==10){
    nsky=1;
    }
    var smo=nsb-2;
    if(smo<0){
    smo=smo+12;
    }
    var nyb=yb0-1;
    if(nyb<0){
    nyb=nyb+12
    }
    if((nyb%3)==0){
    var nskm=((25-smo)%9)+1;
    }
    if((nyb%3)==1){
    nskm=((22-smo)%9)+1;
    }
    if((nyb%3)==2){
    nskm=((19-smo)%9)+1;
    }
    if(GEN==1){
    if(nsky==5){
    var mng=2;
    }
    else{
    mng=nsky;
    }
    }
    else{
    mng=6-nsky;
    if(mng==5){
    mng=8;
    }
    }
    if(mng<1){
    mng=mng+9;
    }
    var str5="Grupo Oeste";
    if(mng==1||mng==3||mng==4||mng==9){
    str5="Grupo Este";
    }
    
    
    
    $('#mng').html("  " + mng); //form.mng.value="  " + mng;
    $('#strgr').html(str5); //form.strgr.value=str5;
    }
    
    function start(form) {
    var nowdate = new Date();
    var sday = nowdate.getDate();
    var smonth = nowdate.getMonth() +1;
    var syear =nowdate.getFullYear();
    var shour = nowdate.getHours();
    var sminute = nowdate.getMinutes();
    form.nnin.value = syear;
    form.nyuet.value = smonth;
    form.nyat.value = sday;
    form.nsee.value = shour;
    form.nfen.value = sminute;
    }
    
    /* $(document).ready(function() {
        //compute();
        $('#calculo4pilares select').selectbox();
    }); */

    export default {compute, start }