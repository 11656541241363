import $ from "jquery";

var fontXXLarge = '90px';
var fontLarge = '60px';
var colorRed = 'rgba(128,0,0,.75)';
var colorGreen = 'rgba(0,64,0,.75)';
var colourBlack = 'rgba(0,0,0,.75)';

const ClickIt = ()=> {
  var jaar = new String("");
  var maand = new String("");

  jaar = $("#jaar").val();
  maand = "3";
  var ijaar = 0;
  var imaand = 0;
  ijaar = parseInt(jaar);
  imaand = parseInt(maand);

/*   if (isNaN(imaand)) {
    imaand = -1;
  }

  if (isNaN(ijaar)) {
    ijaar = -1;
  }

  if (ijaar === -1) {
    alert("Por favor ingrese un año en el formato indicado");
    return;
  } */

  if (imaand === 1) ijaar--;

  $("#spanjaar").html(jaar + " "); // document.all.spanjaar.innerText = jaar + " ";
  $("#laptext").css("visibility", "visible"); // document.all.laptext.style.visibility = "visible";
  //	document.all.mtable.style.visibility = "visible";
  //document.all.im.style.visibility = "hidden";

  var nw, w, ne, s, n, sw, e, se;

  var center = Math.floor(((ijaar - 64) % 180) / 20) + 1;
  $("#m").html(center); // document.all.m.innerText = 	center;
  $("#cnumber").html(center); // document.all.cnumber.innerText = center;
  $("#cnumber1").html(center + " "); // document.all.cnumber1.innerText = center + " " ;
  $("#spanteken").html(GetNaam(center) + " "); // document.all.spanteken.innerText = GetNaam(center) +" ";

  nw = Math.floor(((ijaar - 44) % 180) / 20) + 1;
  w = Math.floor(((ijaar - 24) % 180) / 20) + 1;
  ne = Math.floor(((ijaar - 4) % 180) / 20) + 1;
  s = Math.floor(((ijaar + 16) % 180) / 20) + 1;
  n = Math.floor(((ijaar + 36) % 180) / 20) + 1;
  sw = Math.floor(((ijaar + 56) % 180) / 20) + 1;
  e = Math.floor(((ijaar + 76) % 180) / 20) + 1;
  se = Math.floor(((ijaar + 96) % 180) / 20) + 1;

  $("#nw").html(nw); // document.all.nw.innerText = nw;
  $("#w").html(w); // document.all.w.innerText = w;
  $("#ne").html(ne); // document.all.ne.innerText = ne;
  $("#s").html(s); // document.all.s.innerText = s;
  $("#n").html(n); // document.all.n.innerText = n;
  $("#sw").html(sw); // document.all.sw.innerText = sw;
  $("#e").html(e); // document.all.e.innerText = e;
  $("#se").html(se); // document.all.se.innerText = se;

  $("#nw,#w,#ne,#s,#n,#sw,#e,#se,#m").css({
    color: colourBlack,
    "font-size": fontLarge,
  });
  var grsel = parseInt($("input[name='graden']:checked").val()) - 1;
  //alert (grsel);
  var tmp = grsel % 3;
  var tmpl = Math.floor(grsel / 3);

  var startl, startr;
  var richtingsit;
  var richtingface;

  switch (tmpl) {
    case 0:
      startl = s;
      startr = n;
      richtingsit = "Sur ";
      richtingface = "Norte ";
      $("#n").css({ color: colorGreen, "font-size": fontXXLarge });
      $("#s").css({ color: colorRed, "font-size": fontXXLarge });
      /*document.all.n.style.color = "GREEN";
        document.all.n.style.fontSize = "xx-large";
        document.all.s.style.color = "RED";
        document.all.s.style.fontSize = "xx-large";*/

      break;
    case 1:
      startl = sw;
      startr = ne;
      richtingsit = "Sudoeste ";
      richtingface = "Noreste ";
      $("#ne").css({ color: colorGreen, "font-size": fontXXLarge });
      $("#sw").css({ color: colorRed, "font-size": fontXXLarge });
      /*document.all.ne.style.color = "GREEN";
        document.all.ne.style.fontSize = "xx-large";
        document.all.sw.style.color = "RED";
        document.all.sw.style.fontSize = "xx-large";*/

      break;
    case 2:
      startl = w;
      startr = e;
      richtingsit = "Oeste ";
      richtingface = "Este ";
      $("#e").css({ color: colorGreen, "font-size": fontXXLarge });
      $("#w").css({ color: colorRed, "font-size": fontXXLarge });
      /*document.all.e.style.color = "GREEN";
        document.all.e.style.fontSize = "xx-large";
        document.all.w.style.color = "RED";
        document.all.w.style.fontSize = "xx-large";*/

      break;
    case 3:
      startl = nw;
      startr = se;
      richtingsit = "Noroeste ";
      richtingface = "Sudeste ";
      $("#se").css({ color: colorGreen, "font-size": fontXXLarge });
      $("#nw").css({ color: colorRed, "font-size": fontXXLarge });
      /*document.all.se.style.color = "GREEN";
        document.all.se.style.fontSize = "xx-large";
        document.all.nw.style.color = "RED";
        document.all.nw.style.fontSize = "xx-large";*/

      break;
    case 4:
      startl = n;
      startr = s;
      richtingsit = "Norte ";
      richtingface = "Sur ";
      $("#s").css({ color: colorGreen, "font-size": fontXXLarge });
      $("#n").css({ color: colorRed, "font-size": fontXXLarge });
      /*document.all.s.style.color = "GREEN";
        document.all.s.style.fontSize = "xx-large";
        document.all.n.style.color = "RED";
        document.all.n.style.fontSize = "xx-large";*/

      break;
    case 5:
      startl = ne;
      startr = sw;
      richtingsit = "Noreste ";
      richtingface = "Sudeste ";
      $("#sw").css({ color: colorGreen, "font-size": fontXXLarge });
      $("#ne").css({ color: colorRed, "font-size": fontXXLarge });
      /*document.all.sw.style.color = "GREEN";
        document.all.sw.style.fontSize = "xx-large";
        document.all.ne.style.color = "RED";
        document.all.ne.style.fontSize = "xx-large";*/

      break;
    case 6:
      startl = e;
      startr = w;
      richtingsit = "Este ";
      richtingface = "Oeste ";
      $("#w").css({ color: colorGreen, "font-size": fontXXLarge });
      $("#e").css({ color: colorRed, "font-size": fontXXLarge });
      /*document.all.w.style.color = "GREEN";
        document.all.w.style.fontSize = "xx-large";
        document.all.e.style.color = "RED";
        document.all.e.style.fontSize = "xx-large";*/

      break;
    case 7:
      startl = se;
      startr = nw;
      richtingsit = "Sudeste ";
      richtingface = "Noroeste ";
      $("#nw").css({ color: colorGreen, "font-size": fontXXLarge });
      $("#se").css({ color: colorRed, "font-size": fontXXLarge });
      break;
    default:
      return;
  }

  $("#richtingface").html(richtingface);
  $("#richtingsit").html(richtingsit); 
  $("#facing").html(GetNaam(startr));
  $("#sitting").html(GetNaam(startl));


  $("#ml").html(startl);
  $("#mr").html(startr);

  var stepl, stepr;

  if (startl === 5) {
    if (center % 2 === 0) {
      stepl = -1;
    } else {
      stepl = 1;
    }

    if (tmp !== 0) {
      stepl = -stepl;
    }
  } else {
    if (startl % 2 === 0) {
      stepl = -1;
    } else {
      stepl = 1;
    }
    if (tmp !== 0) {
      stepl = -stepl;
    }
  }

  if (startr === 5) {
    if (center % 2 === 0) {
      stepr = 1;
    } else {
      stepr = -1;
    }

    if (tmp === 0) {
      stepr = -stepr;
    }
  } else {
    if (startr % 2 === 0) {
      stepr = 1;
    } else {
      stepr = -1;
    }
    if (tmp === 0) {
      stepr = -stepr;
    }
  }

  if (stepl === 1) startl += 18;
  else startl += 16;

  if (stepr === 1) startr += 18;
  else startr += 16;

  $("#nwl").html((startl % 9) + 1); // document.all.nwl.innerText = (startl)%9+1;
  $("#wl").html(((startl + 1 * stepl) % 9) + 1); // document.all.wl.innerText = (startl+1*stepl)%9+1;;
  $("#nel").html(((startl + 2 * stepl) % 9) + 1); // document.all.nel.innerText = (startl+2*stepl)%9+1;;
  $("#sl").html(((startl + 3 * stepl) % 9) + 1); // document.all.sl.innerText = (startl+3*stepl)%9+1;;
  $("#nl").html(((startl + 4 * stepl) % 9) + 1); // document.all.nl.innerText = (startl+4*stepl)%9+1;;
  $("#swl").html(((startl + 5 * stepl) % 9) + 1); // document.all.swl.innerText = (startl+5*stepl)%9+1;;
  $("#el").html(((startl + 6 * stepl) % 9) + 1); // document.all.el.innerText = (startl+6*stepl)%9+1;;
  $("#sel").html(((startl + 7 * stepl) % 9) + 1); // document.all.sel.innerText = (startl+7*stepl)%9+1;;

  $("#nwr").html((startr % 9) + 1); // document.all.nwr.innerText = (startr)%9+1;
  $("#wr").html(((startr + 1 * stepr) % 9) + 1); // document.all.wr.innerText = (startr+1*stepr)%9+1;;
  $("#ner").html(((startr + 2 * stepr) % 9) + 1); // document.all.ner.innerText = (startr+2*stepr)%9+1;;
  $("#sr").html(((startr + 3 * stepr) % 9) + 1); // document.all.sr.innerText = (startr+3*stepr)%9+1;;
  $("#nr").html(((startr + 4 * stepr) % 9) + 1); // document.all.nr.innerText = (startr+4*stepr)%9+1;;
  $("#swr").html(((startr + 5 * stepr) % 9) + 1); // document.all.swr.innerText = (startr+5*stepr)%9+1;;
  $("#er").html(((startr + 6 * stepr) % 9) + 1); // document.all.er.innerText = (startr+6*stepr)%9+1;;
  $("#ser").html(((startr + 7 * stepr) % 9) + 1); // document.all.ser.innerText = (startr+7*stepr)%9+1;;
}

const GetNaam = (teken)=>{
  switch (teken){
    case 1:
      return "1 Agua";
    break;
    case 2:
      return "2 Tierra";
    break;
    case 3:
      return "3 Madera";
    break;
    case 4:
      return "4 Madera";
    break;
    case 5:
      return "5 Tierra";
    break;
    case 6:
      return "6 Metal";
    break;
    case 7:
      return "7 Metal";
    break;
    case 8:
      return "8 Tierra";
    break;
    case 9:
      return "9 Fuego";
    break;
    default:
      return;
  }
  return "";
}

const MoveCompass = ()=>{
  var grsel = parseInt($("input[name='graden']:checked").val());
  //alert (grsel);
  var rotacion = (grsel*15)+60;
  var mydelta = Math.abs(rotacion - ($('.aguja').rotate('angle')) )*50;
  //alert (mydelta);
  $('.aguja').rotate({ animateTo:(rotacion),easing: $.easing.easeOutElastic, duration:mydelta });
  $('.aguja_shadow').rotate({ animateTo:(rotacion),easing: $.easing.easeOutElastic, duration:mydelta });
  /* console.log(grsel); */
}

const HideTicket = ()=>{
  ClickIt();
}

const CheckedElement = ()=>{
  const label1 = $('#label1');
  console.log(label1[0].lastChild.checked)
  if(label1[0].lastChild.checked){
    label1.addClass("label-checked");
  }else{
    label1.removeClass("label-checked");
  }

  const label2 = $('#label2');
  console.log(label2[0].lastChild.checked)
  if(label2[0].lastChild.checked){
    label2.addClass("label-checked");
  }else{
    label2.removeClass("label-checked");
  }

  const label3 = $('#label3');
  console.log(label3[0].lastChild.checked)
  if(label3[0].lastChild.checked){
    label3.addClass("label-checked");
  }else{
    label3.removeClass("label-checked");
  }

  const label4 = $('#label4');
  console.log(label4[0].lastChild.checked)
  if(label4[0].lastChild.checked){
    label4.addClass("label-checked");
  }else{
    label4.removeClass("label-checked");
  }

  const label5 = $('#label5');
  console.log(label5[0].lastChild.checked)
  if(label5[0].lastChild.checked){
    label5.addClass("label-checked");
  }else{
    label5.removeClass("label-checked");
  }

  const label6 = $('#label6');
  console.log(label6[0].lastChild.checked)
  if(label6[0].lastChild.checked){
    label6.addClass("label-checked");
  }else{
    label6.removeClass("label-checked");
  }

  const label7 = $('#label7');
  console.log(label7[0].lastChild.checked)
  if(label7[0].lastChild.checked){
    label7.addClass("label-checked");
  }else{
    label7.removeClass("label-checked");
  }

  const label8 = $('#label8');
  console.log(label8[0].lastChild.checked)
  if(label8[0].lastChild.checked){
    label8.addClass("label-checked");
  }else{
    label8.removeClass("label-checked");
  }

  const label9 = $('#label9');
  console.log(label9[0].lastChild.checked)
  if(label9[0].lastChild.checked){
    label9.addClass("label-checked");
  }else{
    label9.removeClass("label-checked");
  }

  const label10 = $('#label10');
  console.log(label10[0].lastChild.checked)
  if(label10[0].lastChild.checked){
    label10.addClass("label-checked");
  }else{
    label10.removeClass("label-checked");
  }

  const label11 = $('#label11');
  console.log(label11[0].lastChild.checked)
  if(label11[0].lastChild.checked){
    label11.addClass("label-checked");
  }else{
    label11.removeClass("label-checked");
  }

  const label12 = $('#label12');
  console.log(label12[0].lastChild.checked)
  if(label12[0].lastChild.checked){
    label12.addClass("label-checked");
  }else{
    label12.removeClass("label-checked");
  }

  const label13 = $('#label13');
  console.log(label13[0].lastChild.checked)
  if(label13[0].lastChild.checked){
    label13.addClass("label-checked");
  }else{
    label13.removeClass("label-checked");
  }

  const label14 = $('#label14');
  console.log(label14[0].lastChild.checked)
  if(label14[0].lastChild.checked){
    label14.addClass("label-checked");
  }else{
    label14.removeClass("label-checked");
  }

  const label15 = $('#label15');
  console.log(label15[0].lastChild.checked)
  if(label15[0].lastChild.checked){
    label15.addClass("label-checked");
  }else{
    label15.removeClass("label-checked");
  }

  const label16 = $('#label16');
  console.log(label16[0].lastChild.checked)
  if(label16[0].lastChild.checked){
    label16.addClass("label-checked");
  }else{
    label16.removeClass("label-checked");
  }

  const label17 = $('#label17');
  console.log(label17[0].lastChild.checked)
  if(label17[0].lastChild.checked){
    label17.addClass("label-checked");
  }else{
    label17.removeClass("label-checked");
  }

  const label18 = $('#label18');
  console.log(label18[0].lastChild.checked)
  if(label18[0].lastChild.checked){
    label18.addClass("label-checked");
  }else{
    label18.removeClass("label-checked");
  }

  const label19 = $('#label19');
  console.log(label19[0].lastChild.checked)
  if(label19[0].lastChild.checked){
    label19.addClass("label-checked");
  }else{
    label19.removeClass("label-checked");
  }

  const label20 = $('#label20');
  console.log(label20[0].lastChild.checked)
  if(label20[0].lastChild.checked){
    label20.addClass("label-checked");
  }else{
    label20.removeClass("label-checked");
  }

  const label21 = $('#label21');
  console.log(label21[0].lastChild.checked)
  if(label21[0].lastChild.checked){
    label21.addClass("label-checked");
  }else{
    label21.removeClass("label-checked");
  }

  const label22 = $('#label22');
  console.log(label22[0].lastChild.checked)
  if(label22[0].lastChild.checked){
    label22.addClass("label-checked");
  }else{
    label22.removeClass("label-checked");
  }

  const label23 = $('#label23');
  console.log(label23[0].lastChild.checked)
  if(label23[0].lastChild.checked){
    label23.addClass("label-checked");
  }else{
    label23.removeClass("label-checked");
  }

  const label24 = $('#label24');
  console.log(label24[0].lastChild.checked)
  if(label24[0].lastChild.checked){
    label24.addClass("label-checked");
  }else{
    label24.removeClass("label-checked");
  }
}


export default {ClickIt, GetNaam, MoveCompass, HideTicket, CheckedElement}
